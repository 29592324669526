import React from 'react';
import { UserBookmark, UserData, UserLink, UserSetting } from '../Data/Model';
import Bookmarks, { BookmarksMode } from '../UI/Bookmarks';
import RestService from '../Services/rest.service';
import localtestRestService from '../Services/localtest.rest.service';
import { loadingIndicatorData, LoadingIndicatorBookmark, loadingIndicatorSelectedItemLinks } from '../Data/loadingIndicatorData';
import { TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Grid, Box, Divider, Typography } from '@material-ui/core';
import LinkItemList from '../UI/LinkItemList';
import LoadingIndicatorTreeViewItem from '../UI/LoadingIndicatorTreeViewItem';
import LoadingIndicatorLinkItemList from '../UI/LoadingIndicatorLinkItemList';
import EditDialog from '../UI/EditDialog';
import AlertDialog from '../UI/AlertDialog';
import Logout from '../UI/Logout';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { isMobileOnly } from 'react-device-detect';
import appInsightsClient from '../Telemetry/telemetryService';
import { AppMode } from '../App';
import * as microsoftTeams from '@microsoft/teams-js';

export interface BootstrapperProps {
  userId: string,
  onLogout: any,
  appMode: AppMode
}

interface EditModeState {
  inEditMode: boolean,
  bookmark?: UserBookmark
}

interface BootstrapperState {
  userId: string,
  userData: UserData,
  openBookmarkIds: Array<string>,
  loading: boolean,
  selectedItem: string,
  selectedItemTitle: string,
  selectedItemLinks: Array<UserLink>,
  editMode: EditModeState,
  inAlertMode: boolean,
  isMobile: boolean,
  isDrawerOpen: boolean
}

export default class Bootstrapper extends React.Component<BootstrapperProps> {
  userData: UserData = {
    bookmarks: [],
    userSetting: {
      lastSelectedItem: ''
    }
  }
  state: BootstrapperState = {
    userId: this.props.userId,
    userData: this.userData,
    openBookmarkIds: [],
    loading: true,
    selectedItem: '',
    selectedItemTitle: '',
    selectedItemLinks: [],
    editMode: {
      inEditMode:false
    },
    inAlertMode: false,
    isMobile: isMobileOnly,
    isDrawerOpen: false
  }

  getOpenBookmarksRecursively = (bookmarks: Array<UserBookmark>) => {
    let result = new Array<string>();
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        if (bookmarks[i].wasOpenInPreviousSession) {
          const openBookmark = bookmarks[i];
          result.push(openBookmark.id);
          if (openBookmark.children && openBookmark.children.length > 0) {
            var childResult = this.getOpenBookmarksRecursively(openBookmark.children);
            result = [...result, ...childResult];
          }
        }
      }
    }
    return result;
  }

  getAllParentBookmarksRecursively = (bookmarks: Array<UserBookmark>) => {
    let result = new Array<string>();
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        const openBookmark = bookmarks[i];
        result.push(openBookmark.id);
        if (openBookmark.children && openBookmark.children.length > 0) {
          var childResult = this.getAllParentBookmarksRecursively(openBookmark.children);
          result = [...result, ...childResult];
        }
      }
    }
    return result;
  }

  getInitialSelectedItemLinksAndTitleRecursively: any = (bookmarks: Array<UserBookmark>, initialSelectedItem:string) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.id.toLowerCase() === initialSelectedItem.toLowerCase()) {
          return [currentBookmark.name, currentBookmark.links];
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = this.getInitialSelectedItemLinksAndTitleRecursively(currentBookmark.children, initialSelectedItem);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  componentDidMount() {
    if(this.props.appMode === AppMode.Test) {
      localtestRestService.get(process.env.REACT_APP_TEST_USER_ID).then((userData: UserData) => {
        this.setUserData(userData);
      });
    } else if (this.props.appMode === AppMode.Stage) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        localtestRestService.get(context.userObjectId).then((userData: UserData) => {
          this.setUserData(userData);
        });
      });
    } else {
      RestService.get(this.state.userId).then((userData: UserData) => {
        this.setUserData(userData);
      });
    }
  }

  setUserData(userData: UserData) {
    let openBookmarks = this.state.isMobile ? this.getAllParentBookmarksRecursively(userData.bookmarks) : this.getOpenBookmarksRecursively(userData.bookmarks);
      var title, links;
      if (userData.userSetting.lastSelectedItem !== '') {
        [title, links] = this.getInitialSelectedItemLinksAndTitleRecursively(userData.bookmarks, userData.userSetting.lastSelectedItem);
      } // Todo: else case of default selected item
      if (links && links.length > 0) {
        this.setState({ selectedItemLinks: links});
      }
      this.setState({ userData: userData, openBookmarkIds: openBookmarks, selectedItem: userData.userSetting.lastSelectedItem, selectedItemTitle: title, loading: false });
  }

  onSelection: any = (selectedItemId: string, selectedItemName: string, selectedItemLinks: Array<UserLink>) => {
    this.setState({selectedItem: selectedItemId, selectedItemTitle: selectedItemName, selectedItemLinks: selectedItemLinks, isDrawerOpen: false });

    let settingTobeCached: UserSetting = {
      lastSelectedItem: selectedItemId
    };

    if(this.props.appMode === AppMode.Test) {
      localtestRestService.setSettings(process.env.REACT_APP_TEST_USER_ID, settingTobeCached).then(() => {
        console.log('Cached item ', selectedItemId);
      });
    } else if (this.props.appMode === AppMode.Stage) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        localtestRestService.setSettings(context.userObjectId, settingTobeCached).then(() => {
          console.log('Cached item ', selectedItemId);
        });
      });
    } else
    {
      RestService.setSettings(this.state.userId, settingTobeCached).then(() => {
        console.log('Cached item ', selectedItemId);
      });
    }
  }

  onInitialFocus: any = (selectedItemLinks: Array<UserLink>) => {
    this.setState({selectedItemLinks: selectedItemLinks});
  }

  findAndDeleteLinkRecursively: any = (bookmarks: Array<UserBookmark>, linkId: string) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.links && currentBookmark.links.length > 0) {
          let matchedIndex = currentBookmark.links.findIndex(link => link.id.toLowerCase() === linkId.toLowerCase());
          if (matchedIndex !== -1) {
            currentBookmark.links.splice(matchedIndex, 1);
            return true;
          }
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = this.findAndDeleteLinkRecursively(currentBookmark.children, linkId);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  onLinkItemDeleted: any = (deletedLinkId: string) => {
    if(this.props.appMode === AppMode.Test) {
      localtestRestService.deleteLink(process.env.REACT_APP_TEST_USER_ID, deletedLinkId).then(() => {
        let userData = this.state.userData;
        // delete link from local cache
        this.findAndDeleteLinkRecursively(userData.bookmarks, deletedLinkId);
        this.setState({ userData: userData });
      });
    } else if (this.props.appMode === AppMode.Stage) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        localtestRestService.deleteLink(context.userObjectId, deletedLinkId).then(() => {
          let userData = this.state.userData;
          // delete link from local cache
          this.findAndDeleteLinkRecursively(userData.bookmarks, deletedLinkId);
          this.setState({ userData: userData });
        });
      });
    } else
    {
      RestService.deleteLink(this.state.userId, deletedLinkId).then(() => {
        let userData = this.state.userData;
        // delete link from local cache
        this.findAndDeleteLinkRecursively(userData.bookmarks, deletedLinkId);
        this.setState({ userData: userData });
      });
    }

    appInsightsClient.trackEvent({name:'LinkItemDeleted'});
  }

  findAndDeleteBookmarkRecursively: any = (bookmarks: Array<UserBookmark>, bookmarkId: string) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.id.toLowerCase() === bookmarkId.toLowerCase()) {
          bookmarks.splice(i, 1);
          return true;
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = this.findAndDeleteBookmarkRecursively(currentBookmark.children, bookmarkId);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  onBookmarkItemDeleted: any = (deletedBookmarkId: string) => {
    console.log('Deleting bookmark: ' + deletedBookmarkId);
    let userData = this.state.userData;
    this.findAndDeleteBookmarkRecursively(userData.bookmarks, deletedBookmarkId);
    this.setState({ userData: userData });

    appInsightsClient.trackEvent({name:'BookmarkItemDeleted'});
  }

  onAlertDialogClosed = () => {
    this.setState({ inAlertMode: false });
  }

  toggleDrawer = () => {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  };

  findAndUpdateBookmarkRecursively: any = (bookmarks: Array<UserBookmark>, bookmarkId: string, updatedValue: string) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.id.toLowerCase() === bookmarkId.toLowerCase()) {
          currentBookmark.name = updatedValue;
          return true;
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = this.findAndUpdateBookmarkRecursively(currentBookmark.children, bookmarkId, updatedValue);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  onBookmarkEnteringEditMode: any = (bookmarkInEditMode: UserBookmark) => {
    const editModeState: EditModeState = {
      inEditMode: true,
      bookmark: bookmarkInEditMode
    }
    this.setState({editMode: editModeState});

    appInsightsClient.trackEvent({name:'BookmarkEnteringEditMode'});
  }

  onBookmarkUpdateComplete = (commitValue: boolean, updatedValue?: string) => {
    const editModeState: EditModeState = {
      inEditMode: false,
      bookmark: undefined
    }

    if (commitValue && this.state.editMode.bookmark) {
      let oldValue = this.state.editMode.bookmark.name;
      let userData = this.state.userData;
      this.findAndUpdateBookmarkRecursively(userData.bookmarks, this.state.editMode.bookmark.id, updatedValue);
      // error handling!
      if (this.props.appMode === AppMode.Test) {
        localtestRestService.editBookmark(process.env.REACT_APP_TEST_USER_ID, this.state.editMode.bookmark.id, updatedValue).then(() => {
          console.log('Updated bookmark name from - ' + oldValue + ' to ' + updatedValue);
        });
      } else if (this.props.appMode === AppMode.Stage) {
        microsoftTeams.initialize();
        microsoftTeams.getContext(context => {
          localtestRestService.editBookmark(context.userObjectId, this.state.editMode.bookmark?.id, updatedValue).then(() => {
            console.log('Updated bookmark name from - ' + oldValue + ' to ' + updatedValue);
          });
        });
      } else {
        RestService.editBookmark(this.state.userId, this.state.editMode.bookmark.id, updatedValue).then(() => {
          console.log('Updated bookmark name from - ' + oldValue + ' to ' + updatedValue);
        });
      }

      this.setState({ userData: userData, selectedItemTitle: updatedValue });
    }

    this.setState({editMode: editModeState});

    appInsightsClient.trackEvent({name:'BookmarkUpdateComplete'});
  }

  render() {
    return (
      <div>
        <Box mr={4}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item>
              <Logout onLogout={this.props.onLogout}/>
            </Grid>
          </Grid>
        </Box>
        <Box my={3} mx={3}>
          <Grid container wrap="nowrap" spacing={3}>
            <Grid item xs={5}>
              {this.state.loading ?
                <TreeView
                  defaultCollapseIcon={<ArrowDropDownIcon />}
                  defaultExpandIcon={<ArrowRightIcon />}
                  defaultEndIcon={<div style={{ width: 24 }} />}
                  defaultExpanded={["0", "0.1", "0.2", "0.3", "1"]}>
                    {loadingIndicatorData.map((bookmarkItem: LoadingIndicatorBookmark) => (
                      <LoadingIndicatorTreeViewItem key={bookmarkItem.id} {...bookmarkItem}/>
                    ))}
                </TreeView>
                : <>{this.state.isMobile ?
                  (<>
                    <IconButton
                      onClick={this.toggleDrawer}
                    >
                      <MenuIcon/>
                    </IconButton>
                    <Drawer
                      open={this.state.isDrawerOpen}
                      onClose={this.toggleDrawer}
                    >
                      <Box my={3} mx={3}>
                        <Bookmarks
                          bookmarksMode={BookmarksMode.PersonalApp}
                          userId={this.state.userId}
                          userBookmarks={this.state.userData.bookmarks}
                          openBookmarkIds={this.state.openBookmarkIds}
                          initialSelectedItem={this.state.selectedItem}
                          setInitialLinks={(selectedItemLinks: Array<UserLink>) => this.onInitialFocus(selectedItemLinks) }
                          onItemSelected={(selectedItem: string, selectedItemName: string, selectedItemLinks: Array<UserLink>) => this.onSelection(selectedItem, selectedItemName, selectedItemLinks)}
                          onChildBookmarkAdded={() => {}}
                          onTopLevelBookmarkAdded={() => {}}
                          onBookmarkEnterEditMode={(bookmark: UserBookmark) => this.onBookmarkEnteringEditMode(bookmark)}
                          onBookmarkDeleted={(deletedBookmarkId: string) => this.onBookmarkItemDeleted(deletedBookmarkId)}
                          onBookmarkDeleteAlert={() => this.setState({inAlertMode: true})}
                          isMobile={this.state.isMobile}/>
                        </Box>
                    </Drawer>
                  </>)
                  :
                  <Bookmarks
                    bookmarksMode={BookmarksMode.PersonalApp}
                    userId={this.state.userId}
                    userBookmarks={this.state.userData.bookmarks}
                    openBookmarkIds={this.state.openBookmarkIds}
                    initialSelectedItem={this.state.selectedItem}
                    setInitialLinks={(selectedItemLinks: Array<UserLink>) => this.onInitialFocus(selectedItemLinks) }
                    onItemSelected={(selectedItem: string, selectedItemName: string, selectedItemLinks: Array<UserLink>) => this.onSelection(selectedItem, selectedItemName, selectedItemLinks)}
                    onChildBookmarkAdded={() => {}}
                    onTopLevelBookmarkAdded={() => {}}
                    onBookmarkEnterEditMode={(bookmark: UserBookmark) => this.onBookmarkEnteringEditMode(bookmark)}
                    onBookmarkDeleted={(deletedBookmarkId: string) => this.onBookmarkItemDeleted(deletedBookmarkId)}
                    onBookmarkDeleteAlert={() => this.setState({inAlertMode: true})}
                    isMobile={this.state.isMobile}/>
                  }</>
              }
              </Grid>
              {!this.state.isMobile &&
                <Grid item>
                  <Divider orientation="vertical" light/>
                </Grid>
              }
              <Grid item xs={12}>
                <Grid container direction="column" spacing={1}>
                  <Grid item>
                    <Box ml={1}>
                      <Typography variant="body1" style={{fontWeight:"bold"}} color="textPrimary">{this.state.selectedItemTitle}</Typography>
                    </Box>
                  </Grid>
                  <Grid item>
                    {this.state.loading ?
                      <LoadingIndicatorLinkItemList links={loadingIndicatorSelectedItemLinks} />
                      : <LinkItemList links={this.state.selectedItemLinks} onLinkDeleted={(deletedItemId: string) => this.onLinkItemDeleted(deletedItemId)}/>
                    }
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {this.state.editMode.inEditMode &&
              <EditDialog
              bookmarkName={this.state.editMode.bookmark? this.state.editMode.bookmark.name : ''}
              onUpdateComplete={(commitValue: boolean, updatedValue?: string) => this.onBookmarkUpdateComplete(commitValue, updatedValue)}/>}
            {this.state.inAlertMode && <AlertDialog onAlertClosed={this.onAlertDialogClosed}/>}
          </Box>
        </div>
    )
  }
}