import axios from 'axios';

class LocalTestRestService {
  baseRoute = process.env.REACT_APP_TEST_BASE_URL;
  get(userId) {
    const route = this.baseRoute + `${userId}`;
    return axios
      .get(route)
      .then(result => result.data);
  }
  getBookmarkItems(userId) {
    const route = this.baseRoute + `${userId}/bookmarkitems`;
    return axios
      .get(route)
      .then(result => result.data);
  }
  setSettings(userId, settings) {
    const route = this.baseRoute + `${userId}/settings`;
    return axios
      .post(route, settings)
      .then(result => result);
  }
  deleteLink(userId, linkId) {
    const route = this.baseRoute + `${userId}/links/${linkId}`;
    return axios
      .delete(route)
      .then(result => result);
  }
  deleteBookmark(userId, bookmarkId) {
    const route = this.baseRoute + `${userId}/bookmarks/${bookmarkId}`;
    return axios
      .delete(route)
      .then(result => result);
  }
  editBookmark(userId, bookmarkId, updatedValue) {
    let updatedBookmark = {
      name: updatedValue
    };
    const route = this.baseRoute + `${userId}/bookmarks/${bookmarkId}`;
    return axios
      .put(route, updatedBookmark)
      .then(result => result);
  }
  getLogoutStatus(userId) {
    const route = this.baseRoute + `${userId}/logoutstatus`;
    return axios
      .get(route)
      .then(result => result.data);
  }
  setLogoutStatus(userId, status) {
    const route = this.baseRoute + `${userId}/logoutstatus`;
    return axios
      .post(route, status)
      .then(result => result);
  }
}

export default new LocalTestRestService();

