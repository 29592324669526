import React from 'react';
import { Typography, Link } from '@material-ui/core';
import EditDialog from '../EditDialog';
const uuidv1 = require('uuid/v1');

interface NewTopLevelBookmarkTreeViewItemProps {
  onTopLevelBookmarkAdded: any
}

const NewTopLevelBookmarkTreeViewItem: React.FC<NewTopLevelBookmarkTreeViewItemProps> = (props: NewTopLevelBookmarkTreeViewItemProps) => {
  const [newBookmarkInEditMode, setNewBookmarkInEditMode] = React.useState(false);

  const handleFocus: any = () => {
    if (!newBookmarkInEditMode) {
      setNewBookmarkInEditMode(true);
    }
  }

  const onBookmarkUpdateComplete = (commitValue: boolean, updatedValue?: string) => {
    if (commitValue && newBookmarkInEditMode) {
      let newBookmark = {id:uuidv1(), name: updatedValue};
      props.onTopLevelBookmarkAdded(newBookmark);
    }
    setNewBookmarkInEditMode(false);
  }

  return(
    <div>
      <Link
        component="button"
        onClick={() => handleFocus()}
        color="primary">
        <div>
          <Typography variant="body2">
            + Create New Bookmark
          </Typography>
        </div>
      </Link>
      {newBookmarkInEditMode &&
          <EditDialog
            bookmarkName={'New Folder'}
            onUpdateComplete={(commitValue: boolean, updatedValue?: string) => onBookmarkUpdateComplete(commitValue, updatedValue)}/>}
      </div>
  );
}

export default NewTopLevelBookmarkTreeViewItem;