import React from 'react';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import * as microsoftTeams from '@microsoft/teams-js';
import appInsightsClient from '../../Telemetry/telemetryService';
import {ReactComponent as SuccessIllustration} from '../../Assets/Illustrations/GoToBookmarks.svg';
import Button from '@material-ui/core/Button';
import { Grid } from '@material-ui/core';

const useSuccessDialogStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary
    },
    button: {
      color: 'white'
    },
    buttonLabel: {
      textTransform: 'none'
    }
  }),
);

const Success: React.FC = () => {
  const staticTabRootURL = 'https://teams.microsoft.com/l/entity/';
  const bookmarksAppId: string = '2d6e372e-2483-425d-bf48-5312ae3ee4a5'; // external app id
  const staticTabEntityId: string = '1123D580-7D73-47CE-89A5-60EA733E7D08';
  const staticTabLink: string = staticTabRootURL + bookmarksAppId + '/' + staticTabEntityId;
  appInsightsClient.trackPageView({name: 'Task-Module/Success'});
  microsoftTeams.initialize();

  const onNavigateToStaticTabClick = (event: React.SyntheticEvent) => {
    appInsightsClient.trackEvent({name: 'navigateToPersonalAppAfterBookmarking'});
    microsoftTeams.executeDeepLink(staticTabLink);
  }
  
  const classes = useSuccessDialogStyles();
  return(
    <Grid container
      direction="column"
      alignItems="center"
      spacing={8}>
        <Grid item>
        </Grid>
        <Grid item>
        <Grid container
          direction="column"
          alignItems="center"
          spacing={4}>
            <Grid item>
              <SuccessIllustration />
            </Grid>
            <Grid item>
              <Grid container
                direction="column"
                alignItems="center"
                spacing={2}>
                  <Grid item>
                    <Typography variant='h6' style={{fontWeight:'bold'}} color="textPrimary">
                      You're all set!
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button 
                      style={{background:'#6264A7'}} 
                      classes={
                        {root: classes.button, label: classes.buttonLabel}
                      }
                      onClick={(event) => onNavigateToStaticTabClick(event)}>
                      View Bookmarks
                    </Button>
                  </Grid>
                </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
  );
}

export default Success;