import React from 'react';
import SSOAuthService from './Services/sso.auth.service';
import RestService from './Services/rest.service';
import localtestRestService from './Services/localtest.rest.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button } from '@material-ui/core';
import BootstrapperTaskModule from './Data/BootstrapperTaskModule';
import { UserBookmarkTaskModule, LogoutStatus } from './Data/Model';
import appInsightsClient from './Telemetry/telemetryService';
import { AppMode } from './App';
import * as microsoftTeams from '@microsoft/teams-js';

interface User {
  userId: string,
  bookmarks: Array<UserBookmarkTaskModule>
};

interface AppLaunchState {
  loading: boolean,
  error: any,
  user?: User,
  loggedOut: boolean,
  loggedOutUserName?: string
};

interface AppLaunchProps {
  appMode: AppMode,
};

export default class AppLaunch extends React.Component<AppLaunchProps> {
  state: AppLaunchState = {
    loading: true,
    error: undefined,
    user: undefined,
    loggedOut: false
  };

  componentDidMount() {
    if(this.props.appMode === AppMode.Test) {
      localtestRestService.getBookmarkItems(process.env.REACT_APP_TEST_USER_ID).then((bookmarkItems: any) => {
        this.setState({
          user: {userId: process.env.REACT_APP_TEST_USER_ID, bookmarks: bookmarkItems},
          loading: false,
          error: null
        });
      });
    } else if (this.props.appMode === AppMode.Stage) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        localtestRestService.getBookmarkItems(context.userObjectId).then((bookmarkItems: any) => {
          this.setState({
            user: {userId: context.userObjectId, bookmarks: bookmarkItems},
            loading: false,
            error: null
          });
        });
      });
    } else {
      appInsightsClient.trackPageView({name: 'AppLaunchTaskModule'});
      var startTime = new Date().getTime();
      try {
        appInsightsClient.startTrackEvent('getAuthTokenTaskModule');
        SSOAuthService
        .getToken()
        .then(() => {
          return SSOAuthService.getUser().then(user => {
            return RestService.getLogoutStatus(user.oid).then((logoutStatus: LogoutStatus) => {
              if (logoutStatus.value === true) {
                this.setState({
                  loading: false, 
                  loggedOut: true,
                  loggedOutUserName: user.name
                });
              } else {
                RestService.getBookmarkItems(user.oid).then(bookmarkItems => {
                  this.setState({
                    user: {userId: user.oid, bookmarks: bookmarkItems},
                    loading: false,
                    error: null
                  });
                });
              }
            appInsightsClient.setAuthenticatedUserContext(user.oid);
            });
          });
        })
        .catch(error => {
          this.setState({
            user: null,
            loading: false,
            error: error
          });
          appInsightsClient.trackException({exception: new Error('getAuthTokenTaskModule failed, exception: ' + error)});
        });
      } finally {
        var elapsed = new Date().getTime() - startTime;
        appInsightsClient.stopTrackEvent('getAuthTokenTaskModule', {elapsedTime: elapsed.toString()});
      }
    }
  }
  login = () => {
    appInsightsClient.trackEvent({name:'loginFromTaskModuleTriggered'});
    appInsightsClient.startTrackEvent('getAuthTokenTaskModuleNewLogin');
    var startTime = new Date().getTime();

    this.setState({loading: true});
    SSOAuthService
      .getToken()
      .then(() => {
        return SSOAuthService.getUser().then(user => {
          RestService.getBookmarkItems(user.oid).then(bookmarkItems => {
            this.setState({
              user: {userId: user.oid, bookmarks: bookmarkItems},
              loading: false,
              error: null
            });
          });

          // Reset logout status
          let logoutStatus: LogoutStatus = {
            value: false
          };
          RestService.setLogoutStatus(this.state.user?.userId, logoutStatus);
        });
      })
      .catch(error => {
        this.setState({
          user: null,
          loading: false,
          error: error
        });
        appInsightsClient.trackException({exception: new Error('getAuthTokenTaskModuleNewLogin failed, exception: ' + error)});
      })
      .finally (() => {
        var elapsed = new Date().getTime() - startTime;
        appInsightsClient.stopTrackEvent('getAuthTokenTaskModuleNewLogin', {elapsedTime: elapsed.toString()});
      })
  };

  render() {
    if (this.state.loading) {
      return (
        <div style={{position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'}}>
          <CircularProgress />
        </div>
      );
    } else {
      if (this.state.user) {
        return (
          <BootstrapperTaskModule userId={this.state.user.userId} bookmarks={this.state.user.bookmarks}/>
        )
      } else if (this.state.error) {
        return (
          <div className="App-error">{JSON.stringify(this.state.error)}</div>
        )
      } else {
        return (
          <Button onClick={this.login}>Sign in</Button>
        )
      }
    }
  }
}