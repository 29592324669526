import React from 'react';
import { LoadingIndicatorBookmark } from '../Data/loadingIndicatorData';
import { TreeItem, Skeleton } from '@material-ui/lab';

export default class LoadingIndicatorTreeViewItem extends React.Component<LoadingIndicatorBookmark> {
  render() {
    return (
      <TreeItem
      nodeId={this.props.id}
      label={<Skeleton variant="text" height={"50"} animation="wave"></Skeleton>}>
        {this.props.children && this.props.children.length > 0 &&
          this.props.children.map(childBookmark => (
          <LoadingIndicatorTreeViewItem key={childBookmark.id} {...childBookmark}/>
        ))}
      </TreeItem>
    )
  }
}