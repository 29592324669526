import React from 'react';
import {Switch, Route} from 'react-router-dom';
import Start from './Auth/Start';
import End from './Auth/End';
import AppLaunch from './AppLaunch';
import AppLaunchTaskModule from './AppLaunchTaskModule';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import * as microsoftTeams from '@microsoft/teams-js';
import Success from './UI/TaskModule/Success';
import Failure from './UI/TaskModule/Failure';

export enum AppMode {
  Test,
  Stage,
  Prod
};

export default class App extends React.Component {
  defaultTheme = createMuiTheme({
    palette: {
      type: 'light',
    },
  });

  state = {
    theme: this.defaultTheme,
    appMode: (window.location.hostname === 'localhost')? AppMode.Test : (window.location.hostname === 'b4t-localtest.azurewebsites.net')? AppMode.Stage : AppMode.Prod
  }

  componentDidMount() {
    if(this.state.appMode !== AppMode.Test) {
      // Call the initialize API first
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        if (context && context.theme) {
          let currentTheme = createMuiTheme({
            palette: {
              type: (context.theme === 'dark') ? 'dark': 'light',
            },
          });
          this.setState({theme: currentTheme});
        }
      });
    }
  }
  render () {
    return (
      <ThemeProvider theme={this.state.theme}>
      <div className="App">
        <Switch>
          <Route path="/silent-start" component={Start}/>
          <Route path="/silent-end" component={End}/>
          <Route path="/task-module">
            <AppLaunchTaskModule appMode={this.state.appMode}/>
          </Route>
          <Route path="/success" component={Success}/>
          <Route path="/failure" component={Failure}/>
          <Route path="/teamsapp">
            <AppLaunch appMode={this.state.appMode}/>
          </Route>
          <Route path="/" component={ () => {
            window.location.href = 'https://home.bookmarksforteams.com';
            return null;
          }}/>
        </Switch>
      </div>
      </ThemeProvider>
    )
  }
}