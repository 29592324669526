import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import AuthenticationContext from 'adal-angular/lib/adal';

export default class End extends React.Component {
  componentDidMount() {
    microsoftTeams.initialize();

      // ADAL.js configuration
      const config = {
        clientId: "aa9069a9-21a1-48f5-a270-3ec7c42655cc",
        redirectUri: window.location.origin + "/silent-end",
        cacheLocation: "localStorage",
        navigateToLoginRequestUrl: false,
      };

      const authContext = new AuthenticationContext(config);
      if (authContext.isCallback(window.location.hash)) {
        authContext.handleWindowCallback(window.location.hash);

        // Only call notifySuccess or notifyFailure if this page is in the authentication popup
        if (window.opener) {
          if (authContext.getCachedUser()) {
            microsoftTeams.authentication.notifySuccess();
          } else {
            microsoftTeams.authentication.notifyFailure(authContext.getLoginError());
          }
        }
      }
  }
  render() {
    return (
      <div>
        <h1>In Silent End!</h1>
      </div>
    );
  }
}