import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Link, Paper, Typography } from '@material-ui/core';
import { UserLink } from '../Data/Model';
import * as microsoftTeams from "@microsoft/teams-js";
import LinearProgress from '@material-ui/core/LinearProgress';
import IconButton from '@material-ui/core/IconButton';
import { ReactComponent as DeleteIcon } from '../Assets/Icons/Remove.svg';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import indigo from '@material-ui/core/colors/indigo';
import {ReactComponent as EmptyStateIllustration} from '../Assets/Illustrations/EmptyStateAddFolder.svg';
import { Grid, Box, Popover } from '@material-ui/core';
import appInsightsClient from '../Telemetry/telemetryService';
import HelpOutlineOutlinedIcon from '@material-ui/icons/HelpOutlineOutlined';
import HelpContent from '../Assets/Help/bookmark.gif';

interface LinkItemListProps {
  links: Array<UserLink>,
  onLinkDeleted: any
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: (theme.palette.type === 'dark') ? indigo[200] : '#3f51b5'
    },
    popoverRoot: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }));
  
const LinkItemList: React.FC<LinkItemListProps> = (props: LinkItemListProps) => {
  const [processingNavigation, setProcessingNavigation] = React.useState(false);
  const [linkUnderNavigation, setLinkUnderNavigation] = React.useState();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
    appInsightsClient.trackEvent({name:'HelpContentViewed'});
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const navigate = (event: React.SyntheticEvent, link: UserLink) => {
    appInsightsClient.trackEvent({name:'ExecutingLinkNavigation'});
    setProcessingNavigation(true);
    setLinkUnderNavigation(link.id);
    microsoftTeams.executeDeepLink(link.url);
  };

  return (
    <div>
      {(props.links && props.links.length>0)?
          <List style={{maxHeight:728, position: 'relative', overflowY: 'auto'}}>
          {props.links && props.links.length > 0 &&
          props.links.map(link => {
            return (
              <Box ml={1} mr={1} key={link.id}>
                <Paper elevation={2}>
                  <Box mb={1}>
                    <ListItem>
                      <ListItemText id={link.id}
                        primary={(processingNavigation && linkUnderNavigation === link.id)?
                                  <LinearProgress/>
                                  : <Link href={link.url} classes={{root: classes.root}} variant="body2" onClick={(event: React.SyntheticEvent<Element, Event>) => navigate(event, link)}>
                                    {link.primaryContent}
                                    </Link>}
                        secondary={(processingNavigation && linkUnderNavigation === link.id)?
                                    ''
                                    : link.secondaryContent}/>
                      <ListItemSecondaryAction>
                        <IconButton edge="end" aria-label="delete" onClick={ () => props.onLinkDeleted(link.id)}>
                            <DeleteIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Box>
                </Paper>
              </Box>
            );
          })}
        </List>
      :<Box mt={3}>
        <Paper elevation={2}>
          <Grid container
            direction="column"
            alignItems="center"
            spacing={4}>
              <Grid item>
                <EmptyStateIllustration/>
              </Grid>
              <Grid item>
                <Grid container
                  direction="column"
                  alignItems="center"
                  spacing={1}>
                    <Grid item>
                      <Typography variant='h6' style={{fontWeight:'bold'}} noWrap>
                        Your tagged messages will show here
                      </Typography>
                    </Grid>
                    <Grid item>
                    <Grid container direction="row" alignItems="center">
                      <Grid item>
                        <Typography variant='body2'>
                          To add a bookmark, go to any message in chat or channel and from the contextual menu, select <span style={{fontWeight: 'bold'}}>More Actions > Bookmark</span>
                        </Typography>
                      </Grid>
                      <Grid item>
                        <IconButton size='small' disableRipple onClick={handleClick}>
                          <HelpOutlineOutlinedIcon fontSize='small' color='primary' />
                        </IconButton>
                      </Grid>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={anchorEl}
                            classes = {{root: classes.popoverRoot}}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'left',
                            }}
                          >
                            <Box border={5} borderColor={"white"}>
                              <img alt="helpContent" width={800} height={450} src={HelpContent}/>
                            </Box>
                          </Popover>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
            </Grid>
          </Paper>
        </Box>
    }
  </div>
  );
}

export default LinkItemList;