import React, { useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Button, Grid, Box} from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { UserBookmarkTaskModule } from '../../Data/Model';
import * as microsoftTeams from "@microsoft/teams-js";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      display: 'none',
    },
    buttonContent: {
      color: 'white'
    },
    wrapper: {
      position: "relative"
    },
    buttonLabel: {
      textTransform: 'none'
    },
    buttonProgress: {
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12
    }
  }),
);

interface ConfirmationOptionsProps {
  bookmarks: Array<UserBookmarkTaskModule>,
  selectedBookmark: any,
};

const ConfirmationOptions: React.FC<ConfirmationOptionsProps> = (props: ConfirmationOptionsProps) => {
  const [userContext, setContext] = useState({});
  const [loading, setLoading] = React.useState(false);

  // Call the initialize API first
  microsoftTeams.initialize();

  // Check the initial theme user chose and respect it
  microsoftTeams.getContext(function(context: microsoftTeams.Context): void {
    if (context) {
      setContext(context);
    }
  });

  const classes = useStyles();

  const handleSave = (event: React.MouseEvent<HTMLElement>, selectedBookmark: string) => {
    console.log('Selected Bookmark is:' + props.selectedBookmark);
    if (!loading) {
      setLoading(true);
    }
    microsoftTeams.tasks.submitTask({context: userContext, bookmarks: props.bookmarks, selectedBookmark: selectedBookmark});
  };

  const handleCancel = () => {
    // Submit empty task to indicate cancellation
    microsoftTeams.tasks.submitTask();
  }

  return(
    <Box mt={4} mr={3}>
      <Grid container spacing={1} justify="flex-end">
        <Grid item>
          <Button
            variant="contained" onClick={() => handleCancel()}
            classes={{label: classes.buttonLabel}}
            style={{background:'#FFFFFF', width: 96}}>
            Cancel
          </Button>
        </Grid>
        <Grid item className={classes.wrapper}>
          <Button
            variant="contained"
            disabled={!props.selectedBookmark || loading}
            classes={{root: classes.buttonContent, label: classes.buttonLabel}}
            onClick={(event) => handleSave(event, props.selectedBookmark)}
            style={{background:'#6264A7', width: 96}}>
            Save
          </Button>
          {loading && <CircularProgress size={24} className={classes.buttonProgress}/>}
        </Grid>
      </Grid>
    </Box>
  )
}

export default ConfirmationOptions;