import React from 'react';
import * as microsoftTeams from '@microsoft/teams-js';
import AuthenticationContext from 'adal-angular/lib/adal';

export default class Start extends React.Component {
  componentDidMount() {
    // Initialize the Teams SDK
    microsoftTeams.initialize();
    // Get the tab context, and use the information to navigate to Azure AD login page
    microsoftTeams.getContext(function (context) {
      // ADAL.js configuration
      const config = {
        // Use the tenant id of the current organization. For guest users, we want an access token for
        // the tenant we are currently in, not the home tenant of the guest.
        tenant: context.tid,
        clientId: "aa9069a9-21a1-48f5-a270-3ec7c42655cc",
        redirectUri: `${window.location.origin}/silent-end`,
        cacheLocation: "localStorage",
        navigateToLoginRequestUrl: false
      };

      const scopes = encodeURIComponent(
        "email openid profile offline_access User.Read"
      );

      // Setup extra query parameters for ADAL
      // - openid and profile scope adds profile information to the id_token
      // - login_hint provides the expected user name
      if (context.loginHint) {
        config.extraQueryParameter = `prompt=consent&scope=${scopes}&login_hint=${encodeURIComponent(context.loginHint)}`;
      } else {
        config.extraQueryParameter = `prompt=consent&scope=${scopes}`;
      }

      // Navigate to the AzureAD login page
      const authContext = new AuthenticationContext(config);
      authContext.login();
    });
  }
  render() {
    return (
      <div>
        <h1>In Silent End!</h1>
      </div>
    );
  }
}