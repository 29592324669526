import * as microsoftTeams from "@microsoft/teams-js";
import TeamsAuthService from "./teams.auth.service";
import axios from 'axios';

// An authentication that will only request an access token for the logged in user.
// This token can then be used to request other resources.
class SSOAuthService {
  constructor() {
    // Initialize the Teams SDK
    microsoftTeams.initialize();

    this.authToken = null;
  }

  isCallback() {
    if (!this.teamsAuthService) {
      this.teamsAuthService = new TeamsAuthService();
    }
    return this.teamsAuthService.isCallback();
  }

  login() {
    if (!this.teamsAuthService) {
      this.teamsAuthService = new TeamsAuthService();
    }
    return this.teamsAuthService.login();
  }

  parseTokenToUser(token) {
    // parse JWT token to object
    var base64Url = token.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var parsedToken = JSON.parse(window.atob(base64));
    var nameParts = parsedToken.name.split(" ");
    return {
      family_name: nameParts.length > 1 ? nameParts[1] : "n/a",
      given_name: nameParts.length > 0 ? nameParts[0] : "n/a",
      upn: parsedToken.preferred_username,
      name: parsedToken.name,
      oid: parsedToken.oid
    };
  }

  getUser() {
    return new Promise((resolve, reject) => {
      if (this.authToken) {
        resolve(this.parseTokenToUser(this.authToken));
      } else {
        this.getToken()
          .resolve(token => {
            resolve(this.parseTokenToUser(token));
          })
          .reject(reason => {
            reject(reason);
          });
      }
    });
  }

  getToken() {
    return new Promise((resolve, reject) => {
      if (this.authToken) {
        resolve(this.authToken);
      } else {
        microsoftTeams.authentication.getAuthToken({
          successCallback: result => {
            this.authToken = result;
            resolve(result);
          },
          failureCallback: reason => {
            reject(reason);
          }
        });
      }
    });
  }

  getAuthHeader() {
    return this.getToken().then(token => {
      let options = {};
      options.headers = {};
      options.headers.Authorization = `Bearer ${token}`;
      return options;
    });
  }

  // Does an authenticated fetch by acquiring and appending the Bearer token for our backend
  fetch(url) {
    return this.getAuthHeader().then(options => {
      return axios.get(url, options);
    });
  }

  setSettings(url, settings) {
    return this.getAuthHeader().then(options => {
      return axios.post(url, settings, options);
    })
  }

  deleteEntity(url) {
    return this.getAuthHeader().then(options => {
      return axios.delete(url, options);
    })
  }

  editEntity(url, updatedValue) {
    return this.getAuthHeader().then(options => {
      return axios.put(url, updatedValue, options);
    })
  }

  getLogoutStatus(url) {
    return this.getAuthHeader().then(options => {
      return axios.get(url, options);
    });
  }

  setLogoutStatus(url, status) {
    return this.getAuthHeader().then(options => {
      return axios.post(url, status, options);
    });
  }
}

export default new SSOAuthService();
