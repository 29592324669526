import React from 'react';
import Bookmarks, { BookmarksMode } from '../UI/Bookmarks';
import ConfirmationOptions from '../UI/TaskModule/ConfirmationOptions';
import { UserBookmarkTaskModule } from './Model';
import EditDialog from '../UI/EditDialog';
import { Grid, Box, Divider} from '@material-ui/core';
import NewTopLevelBookmarkItem from '../UI/TaskModule/NewTopLevelBookmarkTreeViewItem';
import appInsightsClient from '../Telemetry/telemetryService';

interface EditModeState {
  inEditMode: boolean,
  bookmark?: UserBookmarkTaskModule
}

export interface AppLaunchProps {
  userId: string,
  bookmarks: Array<UserBookmarkTaskModule>
}

const AppLaunch: React.FC<AppLaunchProps> = (props: AppLaunchProps) => {
  const [bookmarks, setBookmarks] = React.useState(props.bookmarks);
  const firstBookmarkId = props.bookmarks[0] ? props.bookmarks[0].id : '0';
  const [selectedItem, setSelectedItem] = React.useState(firstBookmarkId);

  const onSelection: any = (selectedBookmarkId: string) => {
    setSelectedItem(selectedBookmarkId);
    return;
  }

  const defaultEditModeState: EditModeState = {
    inEditMode: false
  };
  const [editModeState, setEditModeState] = React.useState(defaultEditModeState);
  const onBookmarkEnteringEditMode = (bookmarkInEditMode: UserBookmarkTaskModule) => {
    const enterEditModeState: EditModeState = {
      inEditMode: true,
      bookmark: bookmarkInEditMode
    }
    setEditModeState(enterEditModeState);
  }

  const findAndUpdateBookmarkRecursively: any = (bookmarks: Array<UserBookmarkTaskModule>, bookmarkId: string, updatedValue: string) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.id.toLowerCase() === bookmarkId.toLowerCase()) {
          currentBookmark.name = updatedValue;
          return true;
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = findAndUpdateBookmarkRecursively(currentBookmark.children, bookmarkId, updatedValue);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  const onBookmarkUpdateComplete = (commitValue: boolean, updatedValue?: string) => {
    const exitEditModeState: EditModeState = {
      inEditMode: false,
      bookmark: undefined
    }

    if (commitValue && editModeState.bookmark) {
      let oldValue = editModeState.bookmark.name;
      let updatedBookmarks = bookmarks;
      findAndUpdateBookmarkRecursively(updatedBookmarks, editModeState.bookmark.id, updatedValue);
      console.log('Updated bookmark name from - ' + oldValue + ' to ' + updatedValue);
      setBookmarks(updatedBookmarks);
    }
    setEditModeState(exitEditModeState);

    appInsightsClient.trackEvent({name:'BookmarkUpdateComplete'});
  }

  const findAndAddChildRecursively: any = (bookmarks: Array<UserBookmarkTaskModule>, bookmarkId: string, childBookmark: UserBookmarkTaskModule) => {
    if (bookmarks && bookmarks.length > 0) {
      for (var i = 0; i < bookmarks.length; i++) {
        let currentBookmark = bookmarks[i];
        if (currentBookmark.id.toLowerCase() === bookmarkId.toLowerCase()) {
          if (!currentBookmark.children) {
            currentBookmark.children = [];
          }
          currentBookmark.children.push(childBookmark);
          return true;
        }
        if (currentBookmark.children && currentBookmark.children.length > 0) {
          let result = findAndAddChildRecursively(currentBookmark.children, bookmarkId, childBookmark);
          if (result) {
            return result;
          }
        }
      }
    }
  }

  const onChildBookmarkAdded = (bookmark: UserBookmarkTaskModule, childBookmark: UserBookmarkTaskModule) => {
    let updatedBookmarks = bookmarks;
    findAndAddChildRecursively(bookmarks, bookmark.id, childBookmark);
    setBookmarks(updatedBookmarks);
    setSelectedItem(childBookmark.id);
    onBookmarkEnteringEditMode(childBookmark);

    appInsightsClient.trackEvent({name:'ChildBookmarkAdded'});
  }

  const onTopLevelBookmarkAdded = (bookmark: UserBookmarkTaskModule) => {
    let updatedBookmarks = [...bookmarks, bookmark];
    setBookmarks(updatedBookmarks);
    setSelectedItem(bookmark.id);

    appInsightsClient.trackEvent({name:'TopLevelBookmarkAdded'});
  }

  return (
    <div>
      <Box ml={4} mr={4} mb={2} mt={1}>
        <Grid container justify="flex-end" spacing={2}>
          <Grid item>
            <NewTopLevelBookmarkItem onTopLevelBookmarkAdded={onTopLevelBookmarkAdded} />
          </Grid>
        </Grid>
      </Box>
      <Box ml={4} mr={4}>
        <Divider />
      </Box>
      <Box ml={4} mr={4}>
        <div style={{overflow:"hidden", overflowY:"auto"}}>
          <Bookmarks
            bookmarksMode={BookmarksMode.TaskModule}
            userId={props.userId}
            userBookmarks={bookmarks}
            openBookmarkIds={[selectedItem]}
            initialSelectedItem={selectedItem}
            setInitialLinks={() => {}}
            onItemSelected={(selectedItem: string) => onSelection(selectedItem)}
            onChildBookmarkAdded={onChildBookmarkAdded}
            onTopLevelBookmarkAdded={onTopLevelBookmarkAdded}
            onBookmarkEnterEditMode={(bookmark: UserBookmarkTaskModule) => onBookmarkEnteringEditMode(bookmark)}
            onBookmarkDeleted={() => {}}
            onBookmarkDeleteAlert={() => {}}
            isMobile = {false} //hard coding this to false since Mobile Task module handles the state well
            />
        </div>
      </Box>
      <Divider />
      <ConfirmationOptions bookmarks={bookmarks} selectedBookmark={selectedItem}/>
      {editModeState.inEditMode &&
        <EditDialog
          bookmarkName={editModeState.bookmark? editModeState.bookmark.name : ''}
          onUpdateComplete={(commitValue: boolean, updatedValue?: string) => onBookmarkUpdateComplete(commitValue, updatedValue)}/>}
    </div>
  );
}

export default AppLaunch;