export interface LoadingIndicatorBookmark {
  id: string,
  children?: Array<LoadingIndicatorBookmark>
}

export interface LoadingIndicatorLink {
  id: string
}

export const loadingIndicatorData: Array<LoadingIndicatorBookmark> = [
  {
    "id": "0",
    "children": [
      {
        "id": "0.1",
        "children": [
          {
            "id": "0.2",
            "children": [
              {
                "id": "0.3",
                "children": undefined
              }
            ]
          },
          {
            "id": "0.3",
            "children": undefined
          }
        ]
      },
      {
        "id": "0.2",
        "children": undefined
      },
      {
        "id": "0.3",
        "children": undefined
      },
      {
        "id": "0.4",
        "children": undefined
      }
    ]
  },
  {
    "id": "1",
    "children": undefined
  }
];

export const loadingIndicatorSelectedItemLinks: Array<LoadingIndicatorLink> = [
  {
    "id": "0.3.0"
  },
  {
    "id": "0.3.1"
  },
  {
    "id": "0.3.2"
  },
  {
    "id": "0.3.3"
  },
  {
    "id": "0.3.4"
  },
  {
    "id": "0.3.5"
  },
  {
    "id": "0.3.6"
  },
  {
    "id": "0.3.7"
  }
];