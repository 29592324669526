import authService from "./sso.auth.service";

class RestService {
  baseRoute = 'https://b4tBlobStorage.azurewebsites.net/user/';
  get(userId) {
    const route = this.baseRoute + `${userId}`;
    return authService
      .fetch(route)
      .then(result => result.data);
  }
  getBookmarkItems(userId) {
    const route = this.baseRoute + `${userId}/bookmarkitems`;
    return authService
      .fetch(route)
      .then(result => result.data);
  }
  setSettings(userId, settings) {
    const route = this.baseRoute + `${userId}/settings`;
    return authService
      .setSettings(route, settings)
      .then(result => result);
  }
  deleteLink(userId, linkId) {
    const route = this.baseRoute + `${userId}/links/${linkId}`;
    return authService
      .deleteEntity(route)
      .then(result => result);
  }
  deleteBookmark(userId, bookmarkId) {
    const route = this.baseRoute + `${userId}/bookmarks/${bookmarkId}`;
    return authService
      .deleteEntity(route)
      .then(result => result);
  }
  editBookmark(userId, bookmarkId, updatedValue) {
    let updatedBookmark = {
      name: updatedValue
    };
    const route = this.baseRoute + `${userId}/bookmarks/${bookmarkId}`;
    return authService
      .editEntity(route, updatedBookmark)
      .then(result => result);
  }
  getLogoutStatus(userId) {
    const route = this.baseRoute + `${userId}/logoutstatus`;
    return authService
      .getLogoutStatus(route)
      .then(result => result.data);
  }
  setLogoutStatus(userId, status) {
    const route = this.baseRoute + `${userId}/logoutstatus`;
    return authService
      .setLogoutStatus(route, status)
      .then(result => result);
  }
}

export default new RestService();
