import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import WarningIcon from '@material-ui/icons/Warning';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';

interface AlertDialogProps {
  onAlertClosed: any
}

const useAlertDialogStyles = makeStyles((theme: Theme) => 
  createStyles({
    title: {
      color: theme.palette.text.primary
    },
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      color: theme.palette.text.secondary,
      flexGrow: 1
    },
    buttonLabel: {
      textTransform: 'none',
      color: 'white'
    },
  }));

const AlertDialog: React.FC<AlertDialogProps> = (props: AlertDialogProps) => {
  const classes = useAlertDialogStyles();
  const [open, setOpen] = React.useState(true);
  const handleClose = () => {
    setOpen(false);
    props.onAlertClosed();
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title" className={classes.title}>{"Bookmark not empty!"}</DialogTitle>
        <DialogContent>
          <div className={classes.labelRoot}>
          <WarningIcon color="inherit" className={classes.labelIcon}/>
            <Typography variant="body2" className={classes.labelText}>
              Please delete all links before proceeding
            </Typography>
          </div>
        </DialogContent>
        <DialogActions>
          <Button 
            onClick={handleClose} 
            variant="contained" 
            style={{background:'#6264A7', width: 96}}
            classes={{label: classes.buttonLabel}}
            autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default AlertDialog;