import React from 'react';
import SSOAuthService from './Services/sso.auth.service';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Button, Grid } from '@material-ui/core';
import Bootstrapper from './Data/Bootstrapper';
import RestService from './Services/rest.service';
import localtestRestService from './Services/localtest.rest.service';
import { LogoutStatus } from './Data/Model';
import {ReactComponent as SuccessIllustration} from './Assets/Illustrations/GoToBookmarks.svg';
import appInsightsClient from './Telemetry/telemetryService';
import { AppMode } from './App';
import * as microsoftTeams from '@microsoft/teams-js';

interface User {
  userId: string
};

interface AppLaunchProps {
  appMode: AppMode
};

interface AppLaunchState {
  loading: boolean,
  error: any,
  user?: User,
  loggedOut: boolean,
  loggedOutUserName?: string
};

export default class AppLaunch extends React.Component<AppLaunchProps> {
  state: AppLaunchState = {
    loading: true,
    error: undefined,
    user: undefined,
    loggedOut: false
  };

  componentDidMount() {
    if(this.props.appMode === AppMode.Test) {
      localtestRestService.getLogoutStatus(process.env.REACT_APP_TEST_USER_ID).then((logoutStatus: LogoutStatus) => {
        this.setLogoutStatusState({name: 'TEST USER', oid: process.env.REACT_APP_TEST_USER_ID}, logoutStatus);
      });
    } else if (this.props.appMode === AppMode.Stage) {
      microsoftTeams.initialize();
      microsoftTeams.getContext(context => {
        localtestRestService.getLogoutStatus(context.userObjectId).then((logoutStatus: LogoutStatus) => {
          this.setLogoutStatusState({name: context.userPrincipalName, oid: context.userObjectId}, logoutStatus);
        });
      });
    } else {
      appInsightsClient.trackPageView({name: 'AppLaunch'});
      
      SSOAuthService
        .getToken()
        .then(() => {
          return SSOAuthService.getUser().then(user => {
            return RestService.getLogoutStatus(user.oid).then((logoutStatus: LogoutStatus) => {
              this.setLogoutStatusState(user, logoutStatus);
            })
          });
        });
        appInsightsClient.trackEvent({name:'Logged in'});
      }
  }

  setLogoutStatusState = (user: any, logoutStatus: LogoutStatus) => {
    if (logoutStatus.value === true) {
      this.setState({
        loading: false, 
        loggedOut: true,
        loggedOutUserName: user.name
      });
    } else {
      this.setState({
        user: {userId: user.oid},
        loading: false,
        error: null
      });
    }
  }

  login = () => {
    this.setState({loading: true});
    SSOAuthService
      .getToken()
      .then(() => {
        return SSOAuthService.getUser().then(user => {
          this.setState({
            user: {userId: user.oid},
            loading: false,
            error: null,
            loggedOut: false
          });

          // Reset logout status
          let logoutStatus: LogoutStatus = {
            value: false
          };
          RestService.setLogoutStatus(this.state.user?.userId, logoutStatus);
          appInsightsClient.trackEvent({name:'relogin'});
        });
      })
      .catch(error => {
        this.setState({
          user: null,
          loading: false,
          error: error
        });
      });
  };

  onLogout = () => {
    if (this.state.user?.userId) {
      this.setState({ loggedOut: true });

      // Set logout status in storage
      let logoutStatus: LogoutStatus = {
        value: true
      };
      RestService.setLogoutStatus(this.state.user.userId, logoutStatus);

      appInsightsClient.trackEvent({name:'LoggedOut'});
    }
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{position: 'absolute', left: '50%', top: '50%',
          transform: 'translate(-50%, -50%)'}}>
          <CircularProgress />
        </div>
      );
    } else {
      if (this.state.loggedOut) {
        return (
          <Grid container
            direction="column"
            alignItems="center"
            spacing={8}>
              <Grid item>
              </Grid>
              <Grid item>
              <Grid container
                direction="column"
                alignItems="center"
                spacing={4}>
                  <Grid item>
                    <SuccessIllustration />
                  </Grid>
                  <Grid item>
                    <Grid container
                      direction="column"
                      alignItems="center"
                      spacing={2}>
                        <Grid item>
                        <Button 
                          onClick={this.login} style={{background:'#6264A7', color:'white', textTransform: 'none'}}>
                        Sign in {this.state.loggedOutUserName? ('as ' + this.state.loggedOutUserName) : ''}</Button>
                        </Grid>
                      </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
        )
      } else if (this.state.user) {
        return (
          <Bootstrapper appMode={this.props.appMode} userId={this.state.user.userId} onLogout={() => this.onLogout()}/>
        )
      } else if (this.state.error) {
        return (
          <div className="App-error">{JSON.stringify(this.state.error)}</div>
        )
      }
    }
  }
}