import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export interface EditDialogProps {
  bookmarkName: string,
  onUpdateComplete: any
}

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      background: '#6264A7',
      '&$disabled': {
        background: 'grey',
      }
    },
    buttonLabelPrimary: {
      textTransform: 'none',
      color: 'white'
    },
    buttonLabelSecondary: {
      textTransform: 'none'
    },
  }),
);

const EditDialog: React.FC<EditDialogProps> = (props: EditDialogProps) => {
  const [open, setOpen] = React.useState(true);
  const [title, setTitle] = React.useState(props.bookmarkName);
  const [saveOption, setSaveOption] = React.useState(false);

  // TODO: Validation
  const handleClose = (commitValue: boolean) => {
    setOpen(false);
    props.onUpdateComplete(commitValue, title);
  };

  const onInput = (event: any) => {
    if (title !== event.target.value) {
      setTitle(event.target.value);
      setSaveOption(true);
    }
  }

  const classes = useStyles();

  return (
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Bookmark name</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please pick a name for the bookmark
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            value={title}
            onChange={event => onInput(event)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
        <Button 
            onClick={() => handleClose(false)} 
            variant="contained" 
            classes={{label: classes.buttonLabelSecondary}}
            style={{background:'#FFFFFF', width: 96}}>
            Cancel
          </Button>
          <Button 
            disabled={!saveOption} 
            onClick={() => handleClose(true)} 
            variant="contained" 
            classes={{root: classes.root, label: classes.buttonLabelPrimary}}
            style={{width: 96}}>
            Save
          </Button>
        </DialogActions>
      </Dialog>
  );
}

export default EditDialog;