import React from 'react';
import { Box, List, ListItem, ListItemText, ListItemSecondaryAction, Paper } from '@material-ui/core';
import { LoadingIndicatorLink } from '../Data/loadingIndicatorData';
import Skeleton from '@material-ui/lab/Skeleton';

interface LoadingIndicatorLinkItemListProps {
  links: Array<LoadingIndicatorLink>
}

export default class LoadingIndicatorLinkItemList extends React.Component<LoadingIndicatorLinkItemListProps> {
  render() {
    return (
      <div>
        {<List>
            {this.props.links && this.props.links.length > 0 && this.props.links.map(link => {
              return (
                <Paper elevation={2}>
                  <Box mb={1}>
                    <ListItem key={link.id} button>
                      <ListItemText id={link.id} 
                        primary={<Skeleton variant="text" height={30} animation="wave"></Skeleton>} 
                        secondary={<Skeleton variant="text"/>}/>
                      <ListItemSecondaryAction>
                      </ListItemSecondaryAction>
                    </ListItem>
                  </Box>
                </Paper>
              );
            })}
          </List>
      }
    </div>
    )
  }
}