import React from 'react';
import BookmarkTreeViewItem from './BookmarkTreeViewItem';
import { TreeView } from '@material-ui/lab';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { UserLink, UserBookmark, UserBookmarkTaskModule } from '../Data/Model';
import { makeStyles, createStyles } from '@material-ui/core/styles';

export enum BookmarksMode {
  TaskModule,
  PersonalApp
};

export interface BookmarkProps {
  bookmarksMode: BookmarksMode,
  userId: string,
  userBookmarks: Array<UserBookmark> | Array<UserBookmarkTaskModule>,
  openBookmarkIds: Array<string>,
  initialSelectedItem: string,
  setInitialLinks: any,
  onItemSelected: any,  
  onChildBookmarkAdded: any,
  onTopLevelBookmarkAdded: any,
  onBookmarkEnterEditMode: any,
  onBookmarkDeleted: any,
  onBookmarkDeleteAlert: any,
  isMobile: boolean
};

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 360,
      flexGrow: 1,
      maxWidth: 525,
    },
  }),
);

const Bookmarks: React.FC<BookmarkProps> = (props: BookmarkProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = React.useState(props.openBookmarkIds);

  const onBookmarkToggled: any = (event: React.ChangeEvent<{}>, nodes: string[]) => {
    setExpanded(nodes);
  }

  const setExpandedNodes = (nodeId: string) => {
    let updateExpandedNodes = [...expanded, nodeId];
    setExpanded(updateExpandedNodes);
  }

  return (
    <TreeView
      className={classes.root}
      defaultCollapseIcon={<ArrowDropDownIcon />}
      defaultExpandIcon={<ArrowRightIcon />}
      defaultEndIcon={<div style={{ width: 24 }} />}
      expanded={expanded}
      onNodeToggle={onBookmarkToggled}>
        {(props.userBookmarks as Array<UserBookmark | UserBookmarkTaskModule>).map(bookmarkItem => (
          <BookmarkTreeViewItem
            key={bookmarkItem.id}
            userId={props.userId}
            bookmarksMode={props.bookmarksMode}
            bookmark={bookmarkItem}
            level={0}
            onItemSelected={(selectedItem: string, selectedItemTitle?: string, selectedItemLinks?: Array<UserLink>) => props.onItemSelected(selectedItem, selectedItemTitle, selectedItemLinks)}
            initialSelectedBookmarkId={props.initialSelectedItem}
            openBookmarkIds= {expanded}
            setExpandedNode={(nodeId: string) => setExpandedNodes(nodeId)}
            onChildHandledHover={() => {}}
            onChildRemovedHover={() => {}}
            onBookmarkEnterEditMode={props.onBookmarkEnterEditMode}
            onChildBookmarkAdded={props.onChildBookmarkAdded}
            onBookmarkDeleted={props.onBookmarkDeleted}
            onBookmarkDeleteAlert={props.onBookmarkDeleteAlert}
            isMobile={props.isMobile}/>
        ))}
    </TreeView>
  );
}

export default Bookmarks;
