import React from 'react';
import Menu from '@material-ui/core/Menu';
import IconButton from '@material-ui/core/IconButton';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import MenuItem from '@material-ui/core/MenuItem';
import { ListItemText } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

const ITEM_HEIGHT = 48;
const useListItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemText: {
      color: theme.palette.text.secondary
    }
  }),
);

export interface OptionsProps {
  options: Array<string>,
  isVisible: boolean,
  onPrimaryAction: any,
  onSecondaryAction: any,
  shouldShowNewFolderOption: boolean
};

const Options: React.FC<OptionsProps> = (props: OptionsProps) => {
  const classes = useListItemStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  }

  const handleClose = (event: React.MouseEvent<HTMLElement>, option: string) => {
    if (option && option === props.options[0]) {
      props.onPrimaryAction();
    } else if (option && option === props.options[1]) {
      props.onSecondaryAction();
    }
    setAnchorEl(null);
    event.stopPropagation();
  };

  const handleFocus = (event: any) => {
    event.stopPropagation();
  }

  const populateOptions: any = (classes: any) => {
    let menuOptions = props.options;
    if (!props.shouldShowNewFolderOption) {
      menuOptions = [props.options[0]];
    }

    // TODO: Re-style options to match the typography on main (Treeview) component
    return menuOptions.map(option => (
      <MenuItem id="menuItem" key={option} onClick={(event) => handleClose(event, option)}>
        <ListItemText primary={option} classes={{primary: classes.listItemText}}/>
      </MenuItem>
    ))
  }

  if (props.isVisible) {
    return (
      <div>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          id="iconButton"
          size= "small"
          onClick={(e) => {handleClick(e)}}
          onFocus={(event) => handleFocus(event)}>
          <MoreIcon />
          <Menu
            id="long-menu"
            anchorEl={anchorEl}
            keepMounted
            open={open}
            onClose={handleClose}
            PaperProps={{
              style: {
                maxHeight: ITEM_HEIGHT * 4.5,
                width: 150,
              },
            }}
          >
          {populateOptions(classes)}
          </Menu>
        </IconButton>
    </div>
    );
  } else {
    return(<div style={{minHeight:'30px'}}></div>);
  }
}

export default Options;