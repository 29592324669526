import React from 'react';
import { Typography, Link } from '@material-ui/core';

export interface LogoutProps {
  onLogout: any
}

const Logout: React.FC<LogoutProps> = (props: LogoutProps) => {
  const handleFocus: any = () => {
    props.onLogout();
  }

  return(
    <Link
      component="button"
      onClick={() => handleFocus()}>
      <div>
        <Typography variant="body2">
          Sign out
        </Typography>
      </div>
    </Link>
  );
}

export default Logout;