import React, { useState } from 'react';
import { TreeItem } from '@material-ui/lab';
import { ReactComponent as FolderIcon } from '../Assets/Icons/FolderImage.svg';
import { UserBookmark, UserBookmarkTaskModule } from '../Data/Model';
import { makeStyles, createStyles, Theme, Typography, useTheme, fade } from '@material-ui/core';
import Options from './Options';
import RestService from '../Services/rest.service';
import { BookmarksMode } from './Bookmarks';
const uuidv1 = require('uuid/v1');

declare module 'csstype' {
  interface Properties {
    '--tree-view-bg-color'?: string;
  }
}

const getBackgroundColorForTheme = (theme: string) => {
  if (theme.toLowerCase() === 'dark') {
    return fade('#6264a7', 0.6);
  } else {
    return '#E5E5F0';
  }
}

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: theme.palette.text.primary,
      '&:focus > $content': {
        backgroundColor: getBackgroundColorForTheme(theme.palette.type),
        fontWeight: 'bold'
      },
    },
    content: {
      borderTopRightRadius: theme.spacing(2),
      borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      backgroundColor: `var(--tree-view-bg-color)`,
    },
    expanded: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(1, 0),
    },
    labelIcon: {
      marginRight: theme.spacing(1),
    },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1
    }
  }),
);

interface BookmarkTreeViewProps {
  userId: string,
  bookmarksMode: BookmarksMode,
  bookmark: UserBookmark | UserBookmarkTaskModule,
  level: number,
  onItemSelected: any,
  initialSelectedBookmarkId: string,
  openBookmarkIds: Array<string>,
  setExpandedNode: any,
  onChildHandledHover: any,
  onChildRemovedHover: any,
  onBookmarkEnterEditMode: any,
  onChildBookmarkAdded: any,
  onBookmarkDeleted: any,
  onBookmarkDeleteAlert: any,
  isMobile: boolean
}

const BookmarkTreeViewItem: React.FC<BookmarkTreeViewProps> = (props: BookmarkTreeViewProps) => {
  const classes = useTreeItemStyles();
  const bookmark = props.bookmark;
  const theme = useTheme<Theme>();

  const handleFocus: any = (event: React.FocusEvent, bookmark: UserBookmark | UserBookmarkTaskModule, setFocusedItem: any) => {
    console.log('Selected Item: ' + bookmark.name);
    if (props.bookmarksMode === BookmarksMode.PersonalApp) {
      const selectedBookmark = bookmark as UserBookmark;
      setFocusedItem(selectedBookmark.id, selectedBookmark.name, selectedBookmark.links);
    } else {
      setFocusedItem(bookmark.id);
    }
    
    event.stopPropagation();
  }

  const setBackgroundColor: any = (bookmark: UserBookmark | UserBookmarkTaskModule, initialSelectedBookmarkId: string) => {
    if (bookmark.id.toLowerCase() === initialSelectedBookmarkId.toLowerCase()) {
      return getBackgroundColorForTheme(theme.palette.type);
    } else {
      return 'default';
    }
  }

  const getFontWeight: any = (bookmark: UserBookmark | UserBookmarkTaskModule, initialSelectedBookmarkId: string) => {
    if (bookmark.id.toLowerCase() === initialSelectedBookmarkId.toLowerCase()) {
      return 'bold';
    } else {
      return 'inherit';
    }
  }

  /* Edit Mode */
  const enterEditMode: any = () => {
    console.log('Entering Edit mode for: ' + bookmark.name);
    props.onBookmarkEnterEditMode(bookmark);
  }

  /* Delete Mode */
  const enterDeleteMode: any = () => {
    console.log('Entering Delete mode for: ' + bookmark.name);
    const deleteRequestedBookmark: UserBookmark = bookmark as UserBookmark;
    if (deleteRequestedBookmark && ((deleteRequestedBookmark.children && deleteRequestedBookmark.children.length > 0) ||
        (deleteRequestedBookmark.links && deleteRequestedBookmark.links.length > 0))) {
          props.onBookmarkDeleteAlert();
        } else {
          // Only allow users to delete empty bookmark folders for now
          RestService.deleteBookmark(props.userId, deleteRequestedBookmark.id).then(() => {
            console.log('Entering Delete mode for: ' + deleteRequestedBookmark.name);
            props.onBookmarkDeleted(deleteRequestedBookmark.id);
          });
        }
  }

  const newItemAdded: any = () => {
    console.log('New Child added for: ' + bookmark.name);
    if (props.level < 3) {
      let newBookmark = {id:uuidv1(), name: 'New Folder'};
      props.onChildBookmarkAdded(bookmark, newBookmark);
      props.setExpandedNode(bookmark.id);
    }
  }

  /* Hover State */
  const [isHoveredOver, setHover] = useState(false);
  const [hasMouseOver, setHasMouseOver] = useState(false);

  const handleMouseEnter = (isMobile: boolean, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (!isMobile) {
      setHasMouseOver(true);
      setHover(true);
      props.onChildHandledHover();
    }
  }

  const handleMouseLeave = (isMobile: boolean, event: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (!isMobile) {
      setHasMouseOver(false);
      setHover(false);
      props.onChildRemovedHover();
    }
  }

  const clearCurrentHoverState = () => {
    setHover(false);
  }

  const handleChildHoverStateRemoved = () => {
    if(hasMouseOver) {
      setHover(true);
    }
  }

  return(
    <div>
      <TreeItem
        nodeId={bookmark.id}
        label={
          <div className={classes.labelRoot}>
            <FolderIcon className={classes.labelIcon}/>
            <Typography variant="body2" className={classes.labelText} style={{fontWeight: getFontWeight(bookmark, props.initialSelectedBookmarkId)}}>
              {bookmark.name}
            </Typography>
            {(props.bookmarksMode === BookmarksMode.TaskModule)? 
              <Options 
                options={['Edit', 'Add New']}
                isVisible={isHoveredOver} 
                shouldShowNewFolderOption={props.level <= 3} 
                onPrimaryAction={enterEditMode} 
                onSecondaryAction={newItemAdded}/>
             :<Options 
                options={['Edit', 'Delete']}
                isVisible={isHoveredOver} 
                shouldShowNewFolderOption={true} 
                onPrimaryAction={enterEditMode} 
                onSecondaryAction={enterDeleteMode}/>
            }
          </div>
        }
        classes={{
          root: classes.root,
          content: classes.content,
          expanded: classes.expanded,
          label: classes.label,
        }}
        onClick={(event) => handleFocus(event, bookmark, props.onItemSelected)}
        onMouseEnter={(event) => handleMouseEnter(props.isMobile, event)}
        onMouseLeave={(event) => handleMouseLeave(props.isMobile, event)}
        style={{
          '--tree-view-bg-color': setBackgroundColor(bookmark, props.initialSelectedBookmarkId),
        }}>
          {bookmark.children && (bookmark.children as Array<UserBookmark | UserBookmarkTaskModule>).map(childBookmark => (
            <BookmarkTreeViewItem
              key={childBookmark.id}
              userId={props.userId}
              bookmarksMode={props.bookmarksMode}
              bookmark={childBookmark}
              level={props.level+1}
              onItemSelected={props.onItemSelected}
              initialSelectedBookmarkId={props.initialSelectedBookmarkId}
              openBookmarkIds={props.openBookmarkIds}
              setExpandedNode={props.setExpandedNode}
              onChildHandledHover={() => clearCurrentHoverState()}
              onChildRemovedHover={() => handleChildHoverStateRemoved()}
              onBookmarkEnterEditMode={props.onBookmarkEnterEditMode}
              onChildBookmarkAdded={props.onChildBookmarkAdded}
              onBookmarkDeleted={props.onBookmarkDeleted}
              onBookmarkDeleteAlert={props.onBookmarkDeleteAlert}
              isMobile={props.isMobile}/>
          ))}
        </TreeItem>
      </div>
  );
}

export default BookmarkTreeViewItem;